import { JSONContent } from '@tiptap/react';
import { uniq } from 'lodash';

export const getNodeSources = (c: JSONContent): string[] => {
  let result: string[] =
    c.attrs?.sourceUrl || [c.attrs?.url?.split('#')[0]].filter(Boolean) || [];
  (c?.content || []).forEach((o: JSONContent) => {
    if (o.attrs?.sourceUrl) {
      if ((o.attrs?.sourceUrl || []).length > 0) {
        result = [...result, ...o.attrs.sourceUrl];
      }
    } else if (o.attrs?.url) {
      result.push(o.attrs.url.split('#')[0]);
    } else {
      result = [...result, ...getNodeSources(o)];
    }
  });

  return uniq(result).filter(Boolean);
};
