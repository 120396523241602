import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { LinkPreview } from '../linkPreviews';

export interface Source extends LinkPreview {
  url: string;
  count: number;
}

export type SourceCount = Pick<Source, 'url' | 'count'>;

export const groupedNoteSourcesState = atom<SourceCount[]>({
  key: 'groupedNoteSources',
  default: [],
});

export const useGroupedNoteSources = (): SourceCount[] =>
  useRecoilValue(groupedNoteSourcesState);

export const useSetGroupedNoteSources = () =>
  useSetRecoilState(groupedNoteSourcesState);
