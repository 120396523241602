import { ButtonBase, styled } from '@mui/material';
import { spacing } from '@mui/system';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { ButtonProps } from '../Button';

export interface CtaLabelProps extends ButtonProps {}

const Button = styled(ButtonBase)(spacing);
const CTA = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  borderRadius: theme.spacing(0.25),
  border: 'none',
  width: 'fit-content',
  minWidth: 'unset',

  '&.infoLight': {
    backgroundColor: `${theme.palette.warning.light}70`,
  },

  '&.infoDark': {
    backgroundColor: theme.palette.warning.light,
  },

  '&:hover': {
    textDecoration: 'none',
    border: 'none',
    backgroundColor: `${theme.palette.warning.light}50`,
  },
}));

const Background = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: theme.spacing(0.25),
  width: 'fit-content',
}));

const CtaLabel: FunctionComponent<CtaLabelProps> = ({
  children,
  className,
  color = 'infoLight',
  ...props
}) => (
  <Background>
    <CTA {...props} className={classNames(className, color)}>
      {children}
    </CTA>
  </Background>
);

export default CtaLabel;
