import {
  atom,
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { localStorageEffect, sessionStorageEffect } from '../utils/recoil';
import { sendMessage } from '../utils/sendBackgroundMessage';

export const sidePanelLayoutVisibilityState = atom<boolean>({
  key: 'sidePanelLayoutVisibility',
  default: false,
  effects: [
    sessionStorageEffect,
    ({ onSet }) => {
      onSet((value) => {
        sendMessage({ type: 'SET_SIDEPANEL_VISIBILITY', value });
      });
    },
  ],
});

export const useSidePanelLayoutVisibilityState = (): [
  boolean,
  SetterOrUpdater<boolean>,
] => useRecoilState(sidePanelLayoutVisibilityState);

export const useSidePanelLayoutVisibility = (): boolean =>
  useRecoilValue(sidePanelLayoutVisibilityState);

export const useSetSidePanelLayoutVisibility = (): SetterOrUpdater<boolean> =>
  useSetRecoilState(sidePanelLayoutVisibilityState);

export const sidePanelLayoutAutoOpenState = atom<boolean>({
  key: 'sidePanelLayoutAutoOpen',
  default: true,
  effects: [sessionStorageEffect],
});

export const useSetSidePanelLayoutAutoOpen = (): SetterOrUpdater<boolean> =>
  useSetRecoilState(sidePanelLayoutAutoOpenState);

export const sidePanelLayoutWidthState = atom<number>({
  key: 'sidePanelLayoutWidth',
  default: 360,
  effects: [localStorageEffect],
});

export const useSidePanelLayoutWidth = (): [number, SetterOrUpdater<number>] =>
  useRecoilState(sidePanelLayoutWidthState);
