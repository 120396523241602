import { createContext, useContext } from 'react';
import { HocuspocusProvider } from '@hocuspocus/provider';
import { Editor, Extension, Node } from '@tiptap/react';

export type EditorContextType = {
  editor?: Editor;
  provider?: HocuspocusProvider;
  extensions: (Extension | Node)[];
};

export const emptyValue: EditorContextType = {
  editor: undefined,
  extensions: [],
  provider: undefined,
};

export const EditorContext = createContext<EditorContextType>(emptyValue);
export const useEditor = () => useContext(EditorContext);

export const EditorSyncedContext = createContext(false);
export const useEditorSynced = () => useContext(EditorSyncedContext);
