import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { sessionStorageEffect } from '@/ext/app/utils/recoil';

export type ColumnTemplateExpanded = 'left' | 'right' | undefined;

export const columnTemplateExpanded = atom<ColumnTemplateExpanded>({
  key: 'columnTemplateExpanded2',
  default: undefined,
  effects: [sessionStorageEffect],
});

export const useColumnTemplateExpandedState = () =>
  useRecoilState(columnTemplateExpanded);

export const useSetColumnTemplateExpanded = () =>
  useSetRecoilState(columnTemplateExpanded);

export const useColumnTemplateExpanded = () =>
  useRecoilValue(columnTemplateExpanded);
