import { ComponentType, Suspense, SuspenseProps } from 'react';

export default function withSuspense<P>(
  Component: ComponentType<P>,
  suspenseProps: SuspenseProps,
): ComponentType<P> {
  const ComponentWithSuspense = (props: P) => (
    <Suspense {...suspenseProps}>
      <Component {...props} />
    </Suspense>
  );
  return ComponentWithSuspense;
}
