import {
  atom,
  SetterOrUpdater,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { useMediaQuery, useTheme } from '@mui/material';

import { sessionStorageEffect } from '../utils/recoil';
import { sidePanelLayoutVisibilityState } from './sidePanelLayout';
import { columnTemplateExpanded } from '@/modules/templates/columnTemplate';

export const notepadPanelOpenState = atom<boolean>({
  key: 'notepadPanelOpen',
  default: false,
  effects: [sessionStorageEffect],
});

export const useNotepadPanelOpenState = (): [
  boolean,
  SetterOrUpdater<boolean>,
] => useRecoilState(notepadPanelOpenState);

export const useNotepadPanelOpen = (): boolean =>
  useRecoilValue(notepadPanelOpenState);

export const useSetNotepadPanelOpen = (): SetterOrUpdater<boolean> => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('md'));

  return useRecoilCallback(
    ({ set }) =>
      (value) => {
        set(notepadPanelOpenState, value);
        set(sidePanelLayoutVisibilityState, value);

        if (small) {
          set(columnTemplateExpanded, 'right');
        }
      },
    [],
  );
};
