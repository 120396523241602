import router, { useRouter } from 'next/router';
import { useCallback } from 'react';

import {
  atom,
  SetterOrUpdater,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { SEARCH_PARAM_KEY } from './constants';

import { Dialog, ValueType } from './types';

export { SEARCH_PARAM_KEY } from './constants';

export type { Dialog };

export const dialogState = atom<ValueType>({
  key: 'dialog',
  default: { dialog: undefined, path: undefined, props: undefined },
});

export const dialogPropsState = atom<string | undefined>({
  key: 'dialogProps',
  default: undefined,
});

export const dialogPageState = atom<boolean>({
  key: 'dialogPageState',
  default: false,
});

export const useDialog = (): ValueType => useRecoilValue(dialogState);

export const useDialogProps = (): string | undefined =>
  useRecoilValue(dialogPropsState);

export const useSetDialog = (): ((
  value: ValueType,
  shouldUpdateQueryParams?: boolean,
  additionalQueryParams?: Record<string, string | undefined>,
) => void) => {
  const setDialog = useSetRecoilState(dialogState);
  const { pathname, query, push } = useRouter();

  const setterOrUpdater = useCallback(
    (
      dialogValue,
      shouldUpdateQueryParams = true,
      additionalQueryParams = {},
    ) => {
      setDialog(dialogValue);
      if (shouldUpdateQueryParams) {
        const newUrl = new URL(window.location.href);

        if (dialogValue.dialog === undefined) {
          newUrl.searchParams.delete(SEARCH_PARAM_KEY);
        } else {
          newUrl.searchParams.set(
            SEARCH_PARAM_KEY,
            `${dialogValue.dialog}${
              dialogValue.path ? `/${dialogValue.path}` : ''
            }`,
          );
        }
        Object.entries(additionalQueryParams).forEach(([key, value]) => {
          if (value === undefined) {
            newUrl.searchParams.delete(key);
          } else if (typeof value === 'string') {
            newUrl.searchParams.set(key, value);
          }
        });
        router.push(newUrl.toString());
      }
    },
    [pathname, query, push, setDialog, router],
  );

  return setterOrUpdater;
};

export const useSetDialogProps = (): SetterOrUpdater<string | undefined> =>
  useSetRecoilState(dialogPropsState);

export const useCloseDialog = (): (() => void) => {
  const setDialog = useSetDialog();

  return () => {
    setDialog({ dialog: undefined, path: undefined });
  };
};
export const useIsDialogOpen = (dialog: Dialog): boolean =>
  useDialog().dialog === dialog;

export const useSetDialogPage = (): SetterOrUpdater<boolean> =>
  useSetRecoilState(dialogPageState);
export const useDialogPage = (): boolean => useRecoilValue(dialogPageState);
